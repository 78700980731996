import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16cae5df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "workbench" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_statistical_panel = _resolveComponent("statistical-panel")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, { gutter: 10 }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topPanelData, (item) => {
          return (_openBlock(), _createBlock(_component_el_col, {
            key: item.title,
            md: 12,
            lg: 6,
            xl: 6
          }, {
            default: _withCtx(() => [
              _createVNode(_component_statistical_panel, { panelData: item }, null, 8, ["panelData"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}