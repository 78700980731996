
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import StatisticalPanel from '@/components/statistical-panel'

export default defineComponent({
  name: 'dashboard',
  components: {
    StatisticalPanel
  },
  setup() {
    const store = useStore()
    store.dispatch('analysis/getHeaderListAction')
    const topPanelData = computed(() => store.state.analysis.topPanelDatas)
    return {
      topPanelData
    }
  }
})
