
import { defineComponent } from 'vue'
import CounterUp from '@/base-ui/count-up'

export default defineComponent({
  components: {
    CounterUp
  },
  props: {
    panelData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  setup() {
    const counterOption1 = {
      decimalPlaces: 0, // 保留两位
      prefix: '' // 单位
    }
    const counterOption2 = {
      decimalPlaces: 0, // 保留两位
      prefix: '￥' // 单位
    }
    return {
      counterOption1,
      counterOption2
    }
  }
})
